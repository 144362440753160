import { paramCase } from 'src/utils/change-case';

import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  HOME: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  mail: `/mail`,
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  blogs: '/blogs',
  blog_details: '/blog/detail',
  specialities: '/specialities',
  blank: `/blank`,
  psychic: {
    details: (id) => `/psychic/${id}`,
  },
  category: {
    details: (slug) => `/category/${slug}`,
  },
  love_readings: `/love-readings`,
  chat: '/chat',
  account: '/account',
  consultants: '/consultants',
  add_consultant: '/add_consultant',
  consultations: '/consultations',
  members: '/members',
  home_info: '/home-info',
  categories: '/categories',
  contact: '/contact-us',
  faqs: '/faqs',
  privacy_policy: '/privacy-policy',
  terms_of_use: '/terms-of-use',
  cookie_policy: '/cookie-policy',
  advisor_terms_and_conditions: '/advisor-terms-and-conditions',
  page403: '/error/403',
  page404: '/error/404',
  page500: '/error/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneStore: 'https://mui.com/store/items/zone-landing-page/',
  minimalStore: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figmaUrl: 'https://www.figma.com/design/cAPz4pYPtQEXivqe11EcDE/%5BPreview%5D-Minimal-Web.v6.0.0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: { details: `/product/${MOCK_ID}` },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: { details: `/post/${paramCase(MOCK_TITLE)}` },
  },
  // AUTH
  auth: {
    amplify: {
      signIn: `${ROOTS.AUTH}/amplify/sign-in`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      signUp: `${ROOTS.AUTH}/amplify/sign-up`,
      updatePassword: `${ROOTS.AUTH}/amplify/update-password`,
      resetPassword: `${ROOTS.AUTH}/amplify/reset-password`,
    },
    jwt: {
      signIn: `${ROOTS.AUTH}/jwt/sign-in`,
      signUp: `${ROOTS.AUTH}/jwt/sign-up`,
    },
    firebase: {
      signIn: `${ROOTS.AUTH}/firebase/sign-in`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      signUp: `${ROOTS.AUTH}/firebase/sign-up`,
      resetPassword: `${ROOTS.AUTH}/firebase/reset-password`,
    },
    auth0: {
      signIn: `${ROOTS.AUTH}/auth0/sign-in`,
    },
    supabase: {
      signIn: `${ROOTS.AUTH}/supabase/sign-in`,
      verify: `${ROOTS.AUTH}/supabase/verify`,
      signUp: `${ROOTS.AUTH}/supabase/sign-up`,
      updatePassword: `${ROOTS.AUTH}/supabase/update-password`,
      resetPassword: `${ROOTS.AUTH}/supabase/reset-password`,
    },
  },
  authDemo: {
    split: {
      signIn: `${ROOTS.AUTH_DEMO}/split/sign-in`,
      signUp: `${ROOTS.AUTH_DEMO}/split/sign-up`,
      resetPassword: `${ROOTS.AUTH_DEMO}/split/reset-password`,
      updatePassword: `${ROOTS.AUTH_DEMO}/split/update-password`,
      verify: `${ROOTS.AUTH_DEMO}/split/verify`,
    },
    centered: {
      signIn: `${ROOTS.AUTH_DEMO}/centered/sign-in`,
      signUp: `${ROOTS.AUTH_DEMO}/centered/sign-up`,
      resetPassword: `${ROOTS.AUTH_DEMO}/centered/reset-password`,
      updatePassword: `${ROOTS.AUTH_DEMO}/centered/update-password`,
      verify: `${ROOTS.AUTH_DEMO}/centered/verify`,
    },
  },
  home: {
    root: ROOTS.HOME,
    // mail: `${ROOTS.HOME}/mail`,
    //  blank: `${ROOTS.HOME}/blank`,
    kanban: `${ROOTS.HOME}/kanban`,
    calendar: `${ROOTS.HOME}/calendar`,
    fileManager: `${ROOTS.HOME}/file-manager`,
    permission: `${ROOTS.HOME}/permission`,
    general: {
      app: `${ROOTS.HOME}/app`,
      ecommerce: `${ROOTS.HOME}/ecommerce`,
      analytics: `${ROOTS.HOME}/analytics`,
      banking: `${ROOTS.HOME}/banking`,
      booking: `${ROOTS.HOME}/booking`,
      file: `${ROOTS.HOME}/file`,
      course: `${ROOTS.HOME}/course`,
    },
    user: {
      root: `${ROOTS.HOME}/user`,
      new: `${ROOTS.HOME}/user/new`,
      list: `${ROOTS.HOME}/user/list`,
      cards: `${ROOTS.HOME}/user/cards`,
      profile: `${ROOTS.HOME}/user/profile`,
      account: `${ROOTS.HOME}/user/account`,
      edit: (id) => `${ROOTS.HOME}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.HOME}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.HOME}/product`,
      new: `${ROOTS.HOME}/product/new`,
      details: (id) => `${ROOTS.HOME}/product/${id}`,
      edit: (id) => `${ROOTS.HOME}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.HOME}/product/${MOCK_ID}`,
        edit: `${ROOTS.HOME}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.HOME}/invoice`,
      new: `${ROOTS.HOME}/invoice/new`,
      details: (id) => `${ROOTS.HOME}/invoice/${id}`,
      edit: (id) => `${ROOTS.HOME}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.HOME}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.HOME}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.HOME}/post`,
      new: `${ROOTS.HOME}/post/new`,
      details: (title) => `${ROOTS.HOME}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.HOME}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.HOME}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.HOME}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.HOME}/order`,
      details: (id) => `${ROOTS.HOME}/order/${id}`,
      demo: {
        details: `${ROOTS.HOME}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.HOME}/job`,
      new: `${ROOTS.HOME}/job/new`,
      details: (id) => `${ROOTS.HOME}/job/${id}`,
      edit: (id) => `${ROOTS.HOME}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.HOME}/job/${MOCK_ID}`,
        edit: `${ROOTS.HOME}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.HOME}/tour`,
      new: `${ROOTS.HOME}/tour/new`,
      details: (id) => `${ROOTS.HOME}/tour/${id}`,
      edit: (id) => `${ROOTS.HOME}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.HOME}/tour/${MOCK_ID}`,
        edit: `${ROOTS.HOME}/tour/${MOCK_ID}/edit`,
      },
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    // mail: `${ROOTS.DASHBOARD}/mail`,

    //  blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
      course: `${ROOTS.DASHBOARD}/course`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
};
